import {DataSnapshot, Database} from '../firebase/Database';
import itiriri from 'itiriri';
import {PersistedItemStatusEnum, StatusActionEnum, VehicleVin} from '@joyrideautos/auction-core/src/types/ItemTypes';
import {
    ItemPath,
    ItemDto,
    PersistedItem,
    AuctionItemImportSourceEnum,
} from '@joyrideautos/auction-core/src/dtos/ItemDto';
import {VehicleInfoDto} from '@joyrideautos/auction-core/src/dtos/VehicleInfoDto';
import {SortingEnum} from '@joyrideautos/auction-core/src/types/Sorting';
import {filterUndef} from '@joyrideautos/auction-utils/src/objectUtils';
import {RowProps} from '@joyrideautos/auction-core/src/dtos/StatusStatisticDto';
import {
    GetVehiclesStatusesStatisticRPCReqData,
    GetVehiclesStatusesStatisticRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/getVehiclesStatusesStatisticReqTypes';
import {
    GetUpcomingAuctionsStatisticRPCResData,
    GetUpcomingAuctionsStatisticRPCReqData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/items/getUpcomingAuctionsStatisticReqTypes';
import {PerformSellVehicleOfflineRPCReqData} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/performSellVehicleOffline';
import {
    RescheduleVehiclesRPCReqData,
    RescheduleVehiclesRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/items/RescheduleVehiclesRPCReqTypes';
import {BidTypeEnum} from '@joyrideautos/auction-core/src/dtos/BidDto';
import {AuctionPath, AuctionOccurrenceDto} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {BaseService} from './BaseService';
import {QueryDocumentSnapshot} from '../firebase/Firestore';
import {WithKey} from '../firebase/types';
import {
    CloneVehicleDataRPCReqData,
    CloneVehicleDataRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/items/cloneVehicleDataReqTypes';
import {
    GetInventoryItemsForAuctionRPCReqData,
    GetInventoryItemsForAuctionRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/items/getInventoryItemsForAuctionRPCReqTypes';
import {toArrayWithKey} from '../utils';

export {isExistingSellerVehicleIdError} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/items/cloneVehicleDataReqTypes';
export type {ExistingSellerVehicleIdErrorRes} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/items/cloneVehicleDataReqTypes';

export type ItemsListenerType = (items: ItemDto[]) => void;
export type ItemPathsListenerType = (items: ItemPath[]) => void;

const makeItemsRef =
    (database: Database) =>
    ({regionId, auctionId}: AuctionPath) => {
        return database.ref(`/${regionId}/items/${auctionId}`).orderByChild('idx');
    };

export class ItemsService extends BaseService {
    subscribeToItems({regionId, auctionId}: AuctionPath, listener: ItemsListenerType): () => void {
        const onSnapshot = (snapshot?: DataSnapshot) => {
            const values: ItemDto[] = [];
            if (snapshot) {
                snapshot.forEach((itemSnapshot: DataSnapshot) => {
                    values.push({
                        ...itemSnapshot.val(),
                        key: itemSnapshot.key,
                        regionId,
                        auctionId,
                    });
                });
            }
            listener(values);
        };

        return this.firebase.database.subscribeToSnapshot(
            makeItemsRef(this.firebase.database)({regionId, auctionId}),
            onSnapshot
        );
    }

    async getInventoryItemsForAuction(auctionPath: AuctionPath) {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_GET_INVENTORY_ITEMS_FOR_AUCTION)<
            GetInventoryItemsForAuctionRPCReqData,
            GetInventoryItemsForAuctionRPCResData
        >(auctionPath);
    }

    async fetchItemsForRegion(regionId: string): Promise<{[key: string]: ItemDto[]}> {
        const itemsForRegionSnap = await this.firebase.database.fetchOnceSnapshot(`/${regionId}/items/`);
        const items: {[key: string]: ItemDto[]} = {};
        if (itemsForRegionSnap) {
            itemsForRegionSnap.forEach((itemsForAuctionSnapshot) => {
                const auctionId = itemsForAuctionSnapshot.key;
                if (auctionId) {
                    const itemSnapshots = itemsForAuctionSnapshot.val();
                    items[auctionId] = Object.entries<ItemDto>(itemSnapshots)
                        .filter(([, itemPerAuction]) => !!itemPerAuction)
                        .map(([key, itemPerAuction]) => {
                            return {
                                ...itemPerAuction,
                                key: key,
                                regionId,
                                auctionId,
                            };
                        });
                }
            });
        }
        return Promise.resolve(items);
    }

    subscribeToUserBiddenItems(
        uid: string,
        listener: ItemPathsListenerType,
        filterBids?: (item: string | {type: BidTypeEnum; timestamp: string}) => boolean
    ): () => void {
        const onSnapshot = (snapshot?: DataSnapshot) => {
            const values: ItemPath[] = [];
            if (snapshot) {
                snapshot.forEach((regionSnap: DataSnapshot) => {
                    const regionId = regionSnap.key!;
                    const auctionsWithItems = regionSnap.val();
                    const itemPaths = itiriri(Object.entries<{[itemId: string]: string}>(auctionsWithItems))
                        .flat(([auctionId, items]) =>
                            Object.keys(items)
                                .filter((itemId) => (filterBids ? filterBids(items[itemId]) : true))
                                .map((itemId) => ({itemId, auctionId, regionId}))
                        )
                        .toArray();

                    values.push(...itemPaths);
                });
            }
            listener(values);
        };

        return this.firebase.database.subscribeToSnapshot(`/users/${uid}/auctionsWithBids`, onSnapshot);
    }

    async fetchFailedDepositItems(uid?: string) {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_USER_GET_VEHICLES_WITH_FAILED_DEPOSIT)<
            {uid?: string},
            WithKey<PersistedItem>[]
        >({uid});
    }

    async fetchItems(itemsToLoad: ItemPath[]): Promise<ItemDto[]> {
        const makePath = ({regionId, auctionId, itemId}: ItemPath) => `${regionId}/items/${auctionId}/${itemId}`;
        const promises = itemsToLoad.map((itemToLoad) =>
            this.firebase.database.fetchOnceSnapshot(makePath(itemToLoad))
        );
        const result = await Promise.all(promises);
        return result
            .map((el, i) => {
                if (!el.exists()) {
                    return null;
                }
                const itemToLoad = itemsToLoad[i];
                return {
                    ...el.val(),
                    key: el.key,
                    regionId: itemToLoad.regionId,
                    auctionId: itemToLoad.auctionId,
                };
            })
            .filter((item) => !!item);
    }

    subscribeToItem(persistenceKey: string, subscriber: (item: WithKey<PersistedItem> | undefined) => void) {
        return this.firebase.firestore.subscribeToDocument<PersistedItem>(
            this.firebase.firestore.documentRef(`/items/${persistenceKey}`),
            (item) => {
                subscriber(item && {...item, key: persistenceKey});
            }
        );
    }

    async fetchItemKey(persistenceKey: string): Promise<WithKey<PersistedItem> | undefined> {
        const item = await this.firebase.firestore.fetchOnce<PersistedItem>(
            this.firebase.firestore.documentRef(`/items/${persistenceKey}`)
        );
        return item ? {...item, key: persistenceKey} : undefined;
    }

    async fetchPersistedItemForAuction(
        {regionId, auctionId, itemId}: ItemPath,
        sellerOrManagerCompanyId?: string
    ): Promise<WithKey<PersistedItem> | undefined> {
        let query = this.firebase.firestore
            .collectionRef<PersistedItem>('/items')
            .where('regionId', '==', regionId)
            .where('auctionId', '==', auctionId)
            .where('itemId', '==', itemId);

        if (sellerOrManagerCompanyId) {
            query = query.where('sellerId', '==', sellerOrManagerCompanyId);
        }

        try {
            const snapshots = await this.firebase.firestore.fetchOnceDocuments<PersistedItem>(query);
            if (!snapshots || snapshots.length > 1) {
                return;
            }
            const persistedItem = snapshots[0].data();
            return persistedItem && {...persistedItem, key: snapshots[0].id};
        } catch (e) {
            this.logFirestoreError(`fetchPersistedItemForAuction: ${regionId}/${auctionId}/${itemId}`, e);
        }
    }

    async fetchItemsCount(sellerId: string): Promise<{[status in PersistedItemStatusEnum]?: number} | undefined> {
        const result = await this.firebase.firestore.fetchOnce<{[status in PersistedItemStatusEnum]?: number}>(
            this.firebase.firestore.documentRef('itemsCount', sellerId)
        );
        return result ?? undefined;
    }

    subscribeToItemsCount(
        sellerId: string,
        subscriber: (itemsCount: {[status in PersistedItemStatusEnum]?: number} | undefined) => void
    ) {
        return this.firebase.firestore.subscribeToDocument<{[status in PersistedItemStatusEnum]?: number}>(
            this.firebase.firestore.documentRef(`/itemsCount/${sellerId}`),
            (itemsCount: ({[status in PersistedItemStatusEnum]?: number} & {key?: string}) | undefined) => {
                if (itemsCount) {
                    delete itemsCount['key'];
                }
                subscriber(itemsCount);
            }
        );
    }

    async calculateItemsCount(sellerId: string, status: PersistedItemStatusEnum) {
        await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_CALCULATE_ITEMS_COUNT)({sellerId, status});
    }

    async fetchItemsForSeller(sellerId: string, status?: PersistedItemStatusEnum): Promise<ItemDto[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_GET_ITEMS_FOR_SELLER)({sellerId, status});
    }

    async fetchItemsForBuyer(
        uid: string,
        status: PersistedItemStatusEnum,
        offset?: number,
        limit?: number,
        order?: SortingEnum
    ): Promise<WithKey<PersistedItem>[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_GET_ITEMS_FOR_BUYER)({
            uid,
            status,
            offset,
            limit,
            order,
        });
    }

    fetchBuyerItemsByStatusLocal(
        status: PersistedItemStatusEnum,
        orderByValues?: {
            field: string;
            direction?: SortingEnum;
        }[],
        initialValues?: any[]
    ) {
        let lastSnapshot: QueryDocumentSnapshot<any> | null;

        const makeQuery = (uid: string, lastSnapshot: QueryDocumentSnapshot<any> | null, limitTo?: number) => {
            const query = this.firebase.firestore
                .collectionRef<PersistedItem>('/items')
                .where('result.uid', '==', uid)
                .where('status', '==', status);

            if (limitTo) {
                query.limit(limitTo);
            }
            if (orderByValues) {
                orderByValues.forEach(({field, direction}) => {
                    query.orderBy(field, direction);
                });
            }
            if (initialValues && !lastSnapshot) {
                query.startAfter(...initialValues);
            } else if (lastSnapshot) {
                query.startAfter(lastSnapshot);
            }
            return query;
        };

        return async ({limit}: {limit?: number}) => {
            const currentUser = this.firebase.auth.currentUser;
            if (!currentUser) {
                return [];
            }

            const snapshots = await this.firebase.firestore.fetchOnceDocuments<PersistedItem>(
                makeQuery(currentUser.uid, lastSnapshot, limit)
            );
            lastSnapshot = snapshots && limit ? snapshots[limit - 1] : null;

            return toArrayWithKey<PersistedItem>(snapshots);
        };
    }

    fetchBuyerWonItemsLocal(
        orderByValues?: {
            field: string;
            direction?: SortingEnum;
        }[],
        initialValues?: any[]
    ) {
        let lastSnapshot: QueryDocumentSnapshot<any> | null;

        const makeQuery = (uid: string, lastSnapshot: QueryDocumentSnapshot<any> | null, limitTo?: number) => {
            const query = this.firebase.firestore
                .collectionRef<PersistedItem>('/items')
                .where('result.uid', '==', uid)
                .where('status', 'in', [
                    PersistedItemStatusEnum.SOLD,
                    PersistedItemStatusEnum.CLAIMED,
                    PersistedItemStatusEnum.PAID,
                ]);

            if (limitTo) {
                query.limit(limitTo);
            }
            if (orderByValues) {
                orderByValues.forEach(({field, direction}) => {
                    query.orderBy(field, direction);
                });
            }
            if (initialValues && !lastSnapshot) {
                query.startAfter(...initialValues);
            } else if (lastSnapshot) {
                query.startAfter(lastSnapshot);
            }
            return query;
        };

        return async ({limit}: {limit?: number}) => {
            const currentUser = this.firebase.auth.currentUser;
            if (!currentUser) {
                return [];
            }

            const snapshots = await this.firebase.firestore.fetchOnceDocuments<PersistedItem>(
                makeQuery(currentUser.uid, lastSnapshot, limit)
            );
            lastSnapshot = snapshots && limit ? snapshots[limit - 1] : null;

            return toArrayWithKey<PersistedItem>(snapshots);
        };
    }

    async addItemsFromVins(
        sellerId: string,
        items: VehicleVin[],
        source?: AuctionItemImportSourceEnum
    ): Promise<{added: VehicleInfoDto[]; ignored: VehicleInfoDto[]}> {
        const result = await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_UPLOAD_ITEMS_CSV)<
            {sellerId: string; items: VehicleVin[]; source?: AuctionItemImportSourceEnum},
            any
        >({
            sellerId,
            items,
            source,
        });

        if (result.error) {
            throw new Error(result.error);
        }

        return result;
    }

    async addItemsFromCSV(
        sellerId: string,
        formData: FormData,
        auctionPath?: AuctionPath,
        source?: AuctionItemImportSourceEnum
    ) {
        const {currentUser} = this.firebase.auth;
        const token = currentUser ? await currentUser.getIdToken() : '';

        formData.append('sellerId', sellerId);
        if (auctionPath) {
            formData.append('regionId', auctionPath.regionId);
            formData.append('auctionId', auctionPath.auctionId);
        }
        if (source) {
            formData.append('source', source);
        }

        return this.rpcService
            .post(this.rpcService.getUrlForRoute(FeReqRouteEnum.API_ITEMS_UPLOAD_ITEMS_CSV), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                const {data} = response.data;
                if (data.error) {
                    return Promise.reject(new Error(data.error));
                }
                return data;
            });
    }

    async fetchWonItemsForBuyer(uid: string, offset?: number, limit?: number): Promise<ItemDto[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_GET_WON_ITEMS_FOR_BUYER)({
            uid,
            offset,
            limit,
        });
    }

    async fetchItemStatus(item: ItemDto): Promise<PersistedItemStatusEnum | undefined> {
        if (!item.persistenceKey) {
            return;
        }
        const persistedItem = await this.fetchItemKey(item.persistenceKey);
        return persistedItem && persistedItem.status;
    }

    async fetchItemsByIds(itemsIds: Array<string | number>): Promise<PersistedItem[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_GET_ITEMS_BY_IDS)({itemsIds});
    }

    async fetchItemsByIdsLocal(itemsIds: Array<string | number>): Promise<WithKey<PersistedItem>[]> {
        // it seems like this is not much slower than by using: where(firebase.firestore.FieldPath.documentId(), 'in', itemIds)
        // but without 'in' limitation (only 10 itemIds)
        const items = await Promise.all(
            itemsIds.map((itemId) =>
                this.firebase.firestore.fetchOnce<WithKey<PersistedItem>>(
                    this.firebase.firestore.documentRef('items', String(itemId))
                )
            )
        );
        return filterUndef(items);
    }

    async updatePersistedItem(persistenceKey: string, item: Partial<PersistedItem>): Promise<void> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_UPDATE_PERSISTED_ITEM)({
            persistenceKey,
            item,
        });
    }

    async updateVehicleStatus(itemKey: string, statusAction: StatusActionEnum): Promise<PersistedItem> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_UPDATE_VEHICLE_STATUS)({
            itemKey,
            statusAction,
        });
    }

    async updateVehiclesStatus(itemKeys: string[], statusAction: StatusActionEnum): Promise<PersistedItem> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_UPDATE_VEHICLES_STATUS)({
            itemKeys,
            statusAction,
        });
    }

    async cloneVehicle(data: CloneVehicleDataRPCReqData): Promise<CloneVehicleDataRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_CLONE_VEHICLE)(data);
    }

    async fetchItemMedia(itemKey: string): Promise<any> {
        const [original, thumb_200, video] = await Promise.all([
            this.firebase.firestore
                .fetchOnceDocument(this.firebase.firestore.documentRef(`/items/${itemKey}`, 'images', 'original'))
                .then((snapshot) => snapshot?.data()),
            this.firebase.firestore
                .fetchOnceDocument(this.firebase.firestore.documentRef(`/items/${itemKey}`, 'images', 'thumb_200'))
                .then((snapshot) => snapshot?.data()),
            this.firebase.firestore
                .fetchOnceDocument(this.firebase.firestore.documentRef(`/items/${itemKey}`, 'images', 'video'))
                .then((snapshot) => snapshot?.data()),
        ]);
        return {original, thumb_200, video};
    }

    async scheduleVehicles(
        regionId: string,
        auctionId: string,
        sellerId: string,
        persistenceKeys: string[]
    ): Promise<void> {
        await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_SCHEDULE_VEHICLES)({
            regionId,
            auctionId,
            sellerId,
            persistenceKeys,
        });
    }

    async reorderVehicles(regionId: string, auctionId: string, persistenceKeys: string[]): Promise<void> {
        await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_REORDER_VEHICLES)({
            regionId,
            auctionId,
            persistenceKeys,
        });
    }

    async fetchCSVData(persistenceKeys: string[]): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_FETCH_VEHICLE_CSV_DATA)(persistenceKeys);
    }

    async fetchAuctionCSVData(rowData: RowProps): Promise<string> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_FETCH_AUCTION_CSV_DATA)(rowData);
    }

    async fetchExcelData(persistenceKeys: string[]): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_FETCH_VEHICLE_EXCEL_DATA)(persistenceKeys);
    }

    async fetchVehiclesStatusesStatistic(data: GetVehiclesStatusesStatisticRPCReqData) {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_FETCH_VEHICLES_STATUSES_STATISTIC)<
            GetVehiclesStatusesStatisticRPCReqData,
            GetVehiclesStatusesStatisticRPCResData
        >(data);
    }

    async fixItemsExpirationInAuction(data: AuctionPath) {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_FIX_ITEMS_EXPIRATION_IN_AUCTION)(data);
    }

    async fetchUpcomingAuctionsData(data: GetUpcomingAuctionsStatisticRPCReqData) {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_FETCH_UPCOMING_AUCTIONS_DATA)<
            GetUpcomingAuctionsStatisticRPCReqData,
            GetUpcomingAuctionsStatisticRPCResData
        >(data);
    }

    async rescheduleVehicles(data: RescheduleVehiclesRPCReqData): Promise<RescheduleVehiclesRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_RESCHEDULE_VEHICLES)(data);
    }

    async unscheduleVehicle(persistenceKey: string): Promise<any> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_UNSCHEDULE_VEHICLE)(persistenceKey);
    }

    async findSellerVehiclesBySellerVehicleId(
        sellerId: string,
        sellerVehicleId: string,
        excludedPersistenceKeys: string[]
    ) {
        const result = await this.firebase.rpcService.call(
            FeReqRouteEnum.API_ITEMS_FIND_SELLER_VEHICLES_BY_SELLER_VEHICLE_ID
        )<{sellerId: string; sellerVehicleId: string; excludedPersistenceKeys: string[]}, WithKey<PersistedItem>[]>({
            sellerId,
            sellerVehicleId,
            excludedPersistenceKeys,
        });
        return result || [];
    }

    async fetchVehiclePropertyRangeForStatus(
        property: string,
        status: PersistedItemStatusEnum
    ): Promise<{min: string; max: string}> {
        const valueForProperty = (item: PersistedItem, property: string) => {
            return property.split('.').reduce((o, key) => o[key], item as any);
        };
        const fetchVehicleValue = async (minMax: 'min' | 'max') => {
            const query = this.firebase.firestore
                .collectionRef<PersistedItem>('/items')
                .where('status', '==', status)
                .orderBy(property, minMax === 'min' ? undefined : 'desc')
                .limit(1);
            const snaps = await this.firebase.firestore.fetchOnceDocuments<PersistedItem>(query);
            const items = toArrayWithKey<PersistedItem>(snaps);
            if (items.length === 0) {
                return;
            }
            return valueForProperty(items[0], property);
        };
        const [min, max] = await Promise.all([fetchVehicleValue('min'), fetchVehicleValue('max')]);
        return {min, max};
    }

    async fetchVehiclesMinMaxBidAmount(
        status: PersistedItemStatusEnum,
        getAuction: (auctionPath: AuctionPath) => AuctionOccurrenceDto | undefined
    ): Promise<{min: number; max: number}> {
        // TODO: move this calculation to BE because it takes a lot of time to calculate (~11-12s);
        if (
            [
                PersistedItemStatusEnum.STORED,
                PersistedItemStatusEnum.CANDIDATE,
                PersistedItemStatusEnum.ARCHIVED,
            ].includes(status)
        ) {
            return {min: 0, max: 0};
        }
        const query = this.firebase.firestore.collectionRef<PersistedItem>('items').where('status', '==', status);

        const auctions = new Set<AuctionOccurrenceDto>();
        for await (const items of this.firebase.firestore.queryIterator(query)) {
            items.forEach(({regionId, auctionId}) => {
                const auction = getAuction({regionId: regionId!, auctionId: auctionId!});
                auction && auctions.add(auction);
            });
        }

        if (!auctions.size) {
            return {min: 0, max: 0};
        }
        const sorted = filterUndef(Array.from(auctions).map((auction) => auction?.settings?.minimumBid)).sort(
            (a, b) => a - b
        );
        const min = sorted[0];
        const max = sorted[sorted.length - 1];
        return {min, max};
    }

    async performSellVehicleOffline(data: PerformSellVehicleOfflineRPCReqData) {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_PERFORM_SELL_VEHICLE_OFFLINE)(data);
    }
}

import {SoldStatusMetaEnum, type PersistedItem} from '@joyrideautos/auction-core/src/dtos/ItemDto';
import {PersistedItemStatusEnum} from '@joyrideautos/auction-core/src/types/ItemTypes';
import {WonItemDto} from '@joyrideautos/auction-core/src/dtos/WonItemDto';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {AuctionWonItemDto} from '@joyrideautos/auction-core/src/dtos/AuctionWonItemDto';
import {BaseService} from './BaseService';
import {DataSnapshot} from '../firebase/Database';
import {Unsubscribe} from '../types';
import {SoldTypeEnum} from '@joyrideautos/auction-core/src/dtos/WinningBidDto';
import {DocumentSnapshot, QueryDocumentSnapshot} from '../firebase/Firestore';
import {SortingEnum} from '@joyrideautos/auction-core/src/types/Sorting';
import {toArrayWithKey} from '../utils';

type UserWonAuctionsListenerType = (wonAuctions: {regionId: string; auctionId: string}[]) => void;
type WonItemsListenerType = (wonItemsByUser: {[key: string]: WonItemDto[]}) => void;

const getWonItemIdentifier = (auctionId: string, itemId: string) => {
    return `${auctionId}-${itemId}`;
};

function mapToWonItems(regionId: string, auctionId: string, value: any) {
    return Object.keys(value).map((itemId) => {
        return {
            id: getWonItemIdentifier(auctionId, itemId),
            regionId,
            auctionId,
            itemId,
            sellerId: value[itemId].sellerId,
        };
    });
}

export class WonItemsService extends BaseService {
    subscribeToWonItems(regionId: string, auctionId: string, listener: WonItemsListenerType): () => void {
        const onSnapshot = (snapshot?: DataSnapshot) => {
            const wonItemsByUser: {
                [userId: string]: WonItemDto[];
            } = {};

            snapshot?.forEach((snapshot) => {
                const uid = snapshot.key;
                if (uid) {
                    let items = wonItemsByUser[uid];
                    if (!items) {
                        items = [];
                        wonItemsByUser[uid] = items;
                    }

                    const wonItemsForUser = snapshot.val();
                    const newUserItems = mapToWonItems(regionId, auctionId, wonItemsForUser);
                    items.push(...(newUserItems as WonItemDto[]));
                }
            });

            listener(wonItemsByUser);
        };

        return this.firebase.database.subscribeToSnapshot(`/${regionId}/wonItems/${auctionId}`, onSnapshot);
    }

    subscribeToPersistedWonItems(
        {uid, auctionId}: {uid?: string; auctionId?: string},
        cb: (items: WithKey<PersistedItem>[]) => void
    ): Unsubscribe {
        const currentUser = this.firebase.auth.currentUser;
        if (!currentUser && !uid) {
            return () => {};
        }
        let query = this.firebase.firestore
            .collectionRef<PersistedItem>('/items')
            .where('result.uid', '==', uid ?? currentUser?.uid)
            .where('result.soldType', '==', SoldTypeEnum.AUCTION)
            .where('status', 'in', [
                PersistedItemStatusEnum.SOLD,
                PersistedItemStatusEnum.PAID,
                PersistedItemStatusEnum.CLAIMED,
            ])
            .orderBy('result.soldType')
            .orderBy('soldAt', 'asc');
        if (auctionId) {
            query = query.where('auctionId', '==', auctionId);
        }
        return this.firebase.firestore.subscribeToCollectionSnap(query, (snapshot) => {
            const items = snapshot?.docs.map((doc) => {
                const item = doc.data();
                return {...item, key: doc.id};
            });
            cb(items || []);
        });
    }

    subscribeToUserWonAuctions(userId: string, listener: UserWonAuctionsListenerType): () => void {
        const onSnapshot = (snapshot?: DataSnapshot) => {
            let result: {regionId: string; auctionId: string}[] = [];

            if (snapshot?.exists()) {
                const val = snapshot.val();
                result = Object.keys(val).reduce((acc, regionId) => {
                    const auctionIds = val[regionId];
                    const wonAuctions = Object.keys(auctionIds).map((auctionId) => ({regionId, auctionId}));

                    return acc.concat(wonAuctions);
                }, result);
            }

            listener(result);
        };

        return this.firebase.database.subscribeToSnapshot(`/users/${userId}/auctionsWithWonItems`, onSnapshot);
    }

    async fetchWonItemsForSeller(sellerId: string, regionId: string): Promise<{[sellerId: string]: WonItemDto[]}> {
        const snapshot = await this.firebase.database.fetchOnceSnapshot(`/${regionId}/wonItems`);
        if (!snapshot.exists()) {
            return Promise.resolve({});
        }

        const wonItemsBySellerId: {
            [sellerId: string]: WonItemDto[];
        } = {};

        snapshot.forEach((child) => {
            const auctionId = child.key!;
            child.forEach((snapshot) => {
                const uid = snapshot.key;
                if (uid) {
                    let items = wonItemsBySellerId[sellerId];
                    if (!items) {
                        items = [];
                        wonItemsBySellerId[sellerId] = items;
                    }
                    const wonItemsForUser = snapshot.val();
                    const newItems = mapToWonItems(regionId, auctionId, wonItemsForUser).filter(
                        (item) => item.sellerId === sellerId
                    );
                    items.push(...(newItems as WonItemDto[]));
                }
            });
        });
        return Promise.resolve(wonItemsBySellerId);
    }

    fetchAuctionsWithWonItems(sorting: SortingEnum) {
        let lastSnapshot: QueryDocumentSnapshot<any> | null;

        const fetchChunk = async (uid: string, lastSnapshot: QueryDocumentSnapshot<any> | null, limitTo?: number) => {
            const query = this.firebase.firestore
                .collectionRef<PersistedItem>('/items')
                .where('result.uid', '==', uid)
                .where('result.soldType', '==', SoldTypeEnum.AUCTION)
                .where('status', 'in', [
                    PersistedItemStatusEnum.SOLD,
                    PersistedItemStatusEnum.CLAIMED,
                    PersistedItemStatusEnum.PAID,
                ])
                .orderBy('result.soldType')
                .orderBy('soldAt', sorting);

            if (limitTo) {
                query.limit(limitTo);
            }
            if (lastSnapshot) {
                query.startAfter(lastSnapshot);
            }

            const snapshots = await this.firebase.firestore.fetchOnceDocuments<PersistedItem>(query);
            return {
                snapshots,
                lastSnapshot: snapshots && limitTo ? snapshots[Math.min(limitTo, snapshots.length) - 1] : null,
            };
        };

        type AuctionInfo = {regionId: string; auctionId: string; itemsCount: number; totalPurchase: number};
        const convertSnapshotsToAuctionInfo = (
            snapshots: DocumentSnapshot<PersistedItem>[] | null,
            auctions: AuctionInfo[]
        ) => {
            return toArrayWithKey<PersistedItem>(snapshots ?? []).reduce<AuctionInfo[]>(
                (auctions, {auctionId, regionId, result}) => {
                    const auction = auctions.find((auction) => auction.auctionId === auctionId);
                    if (auction) {
                        auction.itemsCount += 1;
                        auction.totalPurchase += result?.fees?.total ?? 0;
                    } else {
                        auctions.push({
                            auctionId: auctionId!,
                            regionId: regionId!,
                            itemsCount: 1,
                            totalPurchase: result?.fees?.total ?? 0,
                        });
                    }
                    return auctions;
                },
                auctions
            );
        };

        return async ({limit = 10} = {}) => {
            const currentUser = this.firebase.auth.currentUser;
            if (!currentUser) {
                return [];
            }

            const auctions: AuctionInfo[] = [];

            while (auctions.length < limit) {
                const expectedMaxItemsCountInAuction = 20;
                const {snapshots, lastSnapshot: _lastSnapshot} = await fetchChunk(
                    currentUser.uid,
                    lastSnapshot,
                    limit * expectedMaxItemsCountInAuction
                );
                lastSnapshot = _lastSnapshot;

                convertSnapshotsToAuctionInfo(snapshots, auctions);

                if (!lastSnapshot) {
                    break;
                }
            }

            return auctions;
        };
    }

    async markVehiclesAsPickedUp(persistenceKeys: string[]): Promise<void> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_MARK_VEHICLE_AS_PICKED_UP)({
            persistenceKeys,
        });
    }

    async fetchCurrentUserWonItems(offset?: number, limit?: number): Promise<PersistedItem[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_CURRENT_USER_WON_ITEMS)({offset, limit});
    }

    async fetchCurrentUserPaidItems(offset?: number, limit?: number): Promise<PersistedItem[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_ITEMS_CURRENT_USER_PAID_ITEMS)({offset, limit});
    }

    async fetchCurrentUserClaimedItems(offset?: number, limit?: number): Promise<PersistedItem[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_CURRENT_USER_CLAIMED_ITEMS)({offset, limit});
    }

    subscribeToLoggedInUserWonItemsCount(
        uid: string,
        hasSellerRoleForItem: (item: WithKey<PersistedItem>) => boolean,
        listener: (count: number) => void
    ) {
        const _query = this.firebase.firestore
            .collectionRef<any>('items')
            .where('result.uid', '==', uid)
            .where('result.payInFullAvailable', '==', true)
            .where('status', '==', PersistedItemStatusEnum.SOLD)
            .orderBy('soldAt', 'desc');
        return this.firebase.firestore.subscribeToCollectionSnap(_query, (snapshot) => {
            if (snapshot?.empty) {
                listener(0);
            } else {
                let itemsCount = 0;
                snapshot?.forEach((snap) => {
                    const item = snap.data() as PersistedItem;
                    if (!hasSellerRoleForItem({...item, key: snap.id})) {
                        itemsCount += 1;
                    }
                });
                listener(itemsCount);
            }
        });
    }

    async fetchUserWonItems(uid: string, monthLimit: number): Promise<AuctionWonItemDto[]> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_USER_WON_ITEMS)({uid, monthLimit});
    }

    subscribeToItemPaymentOverdue(uid: string, listener: (items: WithKey<PersistedItem>[]) => void): () => void {
        // no reason          : no order - skip
        // AWATING_PNF_PAYMENT: no order - include - used for complete purchase banner
        // PAYMENT_OVERDUE    : no order - include - used for payment overdue banner
        // PAYMENT_OVERDUE    : new order - include
        // PAYMENT_PROCESSING : pending order - skip
        // PAYMENT_OVERDUE    : failed order - include
        // no reason          : successful order - skip
        const overdueItemsQuery = this.firebase.firestore
            .collectionRef<WithKey<PersistedItem>>('items')
            .where('status', '==', PersistedItemStatusEnum.SOLD)
            .where('result.uid', '==', uid)
            .where('result.payInFullAvailable', '==', true)
            .where('statusMeta.reason', '!=', SoldStatusMetaEnum.PAYMENT_PROCESSING)
            .orderBy('paymentOverdueAt', 'asc')
            .limit(1);

        return this.firebase.firestore.subscribeToCollection<WithKey<PersistedItem>>(overdueItemsQuery, (result) =>
            listener(Object.entries(result || {}).map(([key, value]) => ({...value, key})))
        );
    }

    async getWonVehiclesCount(uid: string, auctionId: string): Promise<number> {
        return this.makeCancelable<number>(`get-won-vehicles-count-${auctionId}-${uid}`)(async () => {
            const query = this.firebase.firestore
                .collectionRef<PersistedItem>('/items')
                .where('result.uid', '==', uid)
                .where('auctionId', '==', auctionId)
                .where('result.soldType', '==', SoldTypeEnum.AUCTION)
                .where('status', 'in', [
                    PersistedItemStatusEnum.SOLD,
                    PersistedItemStatusEnum.CLAIMED,
                    PersistedItemStatusEnum.PAID,
                ]);
            return this.firestore.fetchCollectionCount<PersistedItem>(query);
        });
    }
}

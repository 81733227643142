import {flow, Instance, types} from 'mobx-state-tree';
import {PhoneVerificationStatusEnum} from '@joyrideautos/ui-models/src/types/UserInfo';
import {InputFieldModel} from '@joyrideautos/ui-models/src/smartFields/models/InputFieldModel';
import {declarativeValidator} from '@joyrideautos/ui-models/src/smartFields/validators/Validators';
import {LoadingStatus, withStatus} from '@joyrideautos/ui-models/src/utils/LoadingStatus';
import {AddressFormViewModel} from '@pageComponents/addressForm/AddressFormViewModel';
import {IReactionDisposer, when} from 'mobx';
import BaseViewModel from '@joyrideautos/ui-models/src/BaseViewModel';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {UserTypeEnum} from '@joyrideautos/auction-core/src/dtos/UserDto';
import {COMPANY_NAME} from '@joyrideautos/auction-core/src/constants/Constants';
import {PaymentMethodsViewModel} from '../../../profile/components/settings/payment/PaymentMethodsViewModel';

export enum BuyerProfileStepEnum {
    ACCOUNT_CREATED = 'Account Created',
    VERIFY_EMAIL = 'Verify Email',
    VERIFY_PHONE = 'Verify Phone',
    PROVIDE_ADDRESS = 'Provide Address',
    PAYMENT_METHOD = 'Payment method',
    READY_TO_BID = 'Ready to Bid',
}

interface Step {
    title: BuyerProfileStepEnum;
    description: string;
    isFinished?: boolean;
}

export const BuyerProfileIncompleteViewModel = BaseViewModel.named('BuyerProfileIncompleteViewModel')
    .props({
        code: types.optional(types.string, ''),
        phoneValidationErrorMessage: types.maybe(types.string),
        addressFormViewModel: types.optional(AddressFormViewModel, {}),
        isNotificationError: types.optional(types.boolean, false),
        paymentMethodFormViewModel: types.optional(PaymentMethodsViewModel, {}),
    })
    .volatile(() => ({
        disposers: [] as IReactionDisposer[],
        verifyPhoneStatus: new LoadingStatus(),
        resendPhoneVerificationCodeStatus: new LoadingStatus(),
        emailSentButtonStatus: new LoadingStatus(),
        emailSentTextStatus: new LoadingStatus(),
        addCardStatus: new LoadingStatus(),
    }))
    .actions((self) => {
        return {
            setCode(code?: string) {
                self.code = code || '';
            },
            setNotificationError(isNotificationError: boolean) {
                self.isNotificationError = isNotificationError;
            },
            // eslint-disable-next-line require-yield
            toggleEmailSentText: flow(function* () {
                withStatus(self.emailSentTextStatus)(
                    async () =>
                        new Promise<void>((resolve) => {
                            setTimeout(() => {
                                resolve();
                            }, 15000);
                        })
                ).catch(logError());
            }),
            getPhoneVerificationMessage(
                phoneInProfile: boolean | undefined,
                phoneVerificationStatus: PhoneVerificationStatusEnum | undefined
            ) {
                if (!phoneInProfile) {
                    return "We noticed that you don't have a verified phone number on file.";
                }
                switch (phoneVerificationStatus) {
                    case PhoneVerificationStatusEnum.NOT_VERIFIED:
                        return "We noticed that you don't have a verified phone number on file.";
                    case PhoneVerificationStatusEnum.REQUEST_SENT:
                        return 'We sent you text message to verify phone number, please respond.';
                    default:
                        return '';
                }
            },
        };
    })
    .views((self) => {
        const pinConnector = new InputFieldModel(declarativeValidator('required|digits:4'), true);

        return {
            get userInfo() {
                return self.authUserStore.userInfo;
            },
            get codeViewModel() {
                return pinConnector.connect(self.code, self.setCode);
            },
            get phoneInProfile() {
                return this.userInfo && !!this.userInfo.phone;
            },
            get phoneVerificationStatus() {
                return self.authUserStore.phoneVerificationStatus();
            },
            get profileCompleted() {
                return Boolean(this.userInfo && !!this.userInfo.profileCompletedAt);
            },
            get steps(): Step[] {
                return [
                    {
                        title: BuyerProfileStepEnum.ACCOUNT_CREATED,
                        description:
                            'Welcome aboard. You’ll just need to complete a few more steps to finish your profile.',
                        isFinished: !this.userInfo?.isAnonymous,
                    },
                    {
                        title: BuyerProfileStepEnum.VERIFY_EMAIL,
                        description: !this.userInfo?.emailVerified
                            ? 'We just sent you a verification email. Please follow the instructions to finish registering your account.'
                            : '',
                        isFinished: this.userInfo?.emailVerified,
                    },
                    {
                        title: BuyerProfileStepEnum.VERIFY_PHONE,
                        description: self.getPhoneVerificationMessage(
                            this.phoneInProfile,
                            this.phoneVerificationStatus
                        ),
                        isFinished: this.userInfo?.phoneVerified,
                    },
                    {
                        title: BuyerProfileStepEnum.PROVIDE_ADDRESS,
                        description: !this.userInfo?.addressProvided
                            ? 'We need your address for proper DMV reporting and to ensure a smooth pick up process.'
                            : '',
                        isFinished: this.userInfo?.addressProvided,
                    },
                    {
                        title: BuyerProfileStepEnum.PAYMENT_METHOD,
                        description: !this.userInfo?.hasDefaultPaymentMethod
                            ? 'Add a credit card to your profile and start bidding now!'
                            : '',
                        isFinished: this.userInfo?.hasDefaultPaymentMethod,
                    },
                    {
                        title: BuyerProfileStepEnum.READY_TO_BID,
                        description: `Thanks for signing up. We’re thrilled to have you onboard ${COMPANY_NAME}.`,
                    },
                ];
            },
            get sortedSteps(): Step[] {
                const sortByFinishedFirst = (a: Step, b: Step) => {
                    if (a.isFinished && !b.isFinished) {
                        return -1;
                    } else if (!a.isFinished && b.isFinished) {
                        return 1;
                    } else {
                        return 0;
                    }
                };
                return this.steps.sort(sortByFinishedFirst);
            },
            get currentStep(): BuyerProfileStepEnum {
                const step = this.sortedSteps.find((step) => !step.isFinished);
                return step!.title;
            },
            get currentStepIndex(): number {
                return this.sortedSteps.findIndex((step) => !step.isFinished);
            },
            get showPromo(): boolean {
                return (
                    this.currentStep === BuyerProfileStepEnum.VERIFY_EMAIL ||
                    this.currentStep === BuyerProfileStepEnum.VERIFY_PHONE ||
                    this.currentStep === BuyerProfileStepEnum.PROVIDE_ADDRESS ||
                    (this.currentStep === BuyerProfileStepEnum.PAYMENT_METHOD &&
                        this.userInfo?.userType !== UserTypeEnum.SELLER)
                );
            },
            get isVisibleAlert() {
                return this.showPromo && !(!this.userInfo || !this.userInfo.status.isReady);
            },
        };
    })
    .actions((self) => ({
        signOut: flow(function* () {
            yield self.authUserStore.signOut();
        }),
        checkVerificationCode: flow(function* () {
            self.phoneValidationErrorMessage = undefined;
            try {
                const result = yield withStatus(self.verifyPhoneStatus)(() =>
                    self.authUserStore.checkPhoneVerificationCode(self.code, true)
                );
                if (result && result.status !== 'approved') {
                    self.phoneValidationErrorMessage = 'We are unable to confirm provided verification code';
                }
            } catch (e: any) {
                self.logger.log(e);
                self.phoneValidationErrorMessage = e.message;
            }
        }),
        resendVerificationCode: flow(function* () {
            self.phoneValidationErrorMessage = undefined;
            yield withStatus(self.resendPhoneVerificationCodeStatus)(() =>
                self.authUserStore.sendPhoneVerificationCode()
            );
        }),
        resendVerificationEmail: flow(function* () {
            self.setNotificationError(false);
            try {
                yield withStatus(self.emailSentButtonStatus)(() =>
                    self.rootStore.userService.sendEmailVerificationForUser(self.userInfo!.uid)
                );
            } catch (e) {
                self.setNotificationError(true);
                console.error(e);
            }
            self.toggleEmailSentText().catch(logError());
        }),
        setProfileCompleted() {
            self.userInfo && self.rootStore.userService.setUserProfileCompleted(self.userInfo.uid).catch(logError());
        },
    }))
    .actions((self) => ({
        afterCreate() {
            self.disposers.push(
                when(
                    () =>
                        Boolean(self.userInfo && !self.userInfo.profileCompletedAt) &&
                        self.currentStep === BuyerProfileStepEnum.READY_TO_BID,
                    () => {
                        self.setProfileCompleted();
                    }
                )
            );
        },
        beforeDestroy() {
            self.disposers.forEach((disposer) => disposer());
        },
    }));

export interface BuyerProfileIncompleteViewModelType extends Instance<typeof BuyerProfileIncompleteViewModel> {}

import {UserBidData} from './BidDto';
import {
    IdentityVerificationIntentDto,
    PaymentTypeEnum,
    StripeIdentityVerificationSessionStatusEnum,
    StripeResponseDto,
} from './PaymentsDto';

export enum UserTypeEnum {
    BUYER = 'buyer',
    SELLER = 'seller',
}

export enum BanReasonEnum {
    FAILED_DEPOSIT = 'FAILED_DEPOSIT',
    PREVIOUSLY_BANNED_PHONE_OR_EMAIL = 'PREVIOUSLY_BANNED_PHONE_OR_EMAIL',
    PREVIOUSLY_BANNED_PAYMENT_METHOD = 'PREVIOUSLY_BANNED_PAYMENT_METHOD',
    FRAUD_OR_BAD_ACTOR = 'FRAUDULENT_OR_BAD_ACTOR',
    BANNED_FOR_MULTIPLE_AUCTIONS = 'BANNED_FOR_MULTIPLE_AUCTIONS',
    OTHER = 'OTHER',
    EXPIRED_PAYMENT = 'EXPIRED_PAYMENT',
}

// We use value "ADMIN" for backwards compatibility, it actually means system ban, not by our admin users
export const BANNED_BY_SYSTEM = 'ADMIN';

// Used as sellerId value of global ban document in userBans collection
export const GLOBAL_BAN_ALL = 'ALL';
// Used as key of global ban document in userBans collection
export const GLOBAL_BAN_KEY = 'GLOBAL';

export enum UserRoleEnum {
    SELLERS = 'sellers',
    MANAGERS = 'managers',
    ADMINS = 'admins',
    // If not any of this - then it's a buyer
}

export interface BannedUser {
    bannedBy: string;
    bannedSince: string;
    uid: string;
    sellerId: string;
    banReasons?: {[key: string]: boolean};
    bannedUntil?: string;
}

export interface UserCounters {
    wonItemsCount?: number;
    bidsCount?: number;
    autoBidsCount?: number;
}

export interface UserCredentials {
    uid: string;
    isAnonymous: boolean;
    email: string | null;
    emailVerified: boolean;
}

export interface UserAddress {
    city: string;
    state: string;
    street: string;
    apartment?: string;
}

export interface PaymentCustomerInfo {
    paymentProvider?: string;
    paymentCustomerId?: string;
}

export interface UserPaymentInfo extends PaymentCustomerInfo {}

export interface UserSettings {
    address?: UserAddress;
    paymentInfo?: UserPaymentInfo;
}

export type StripeSession = StripeResponseDto<IdentityVerificationIntentDto>;

export interface IdentityVerifiedByStripe {
    verificationIntents: IdentityVerificationIntentDto[];
    status: StripeIdentityVerificationSessionStatusEnum;
    lastVerificationError?: string;
}

export type FailedDeposit = {
    paymentResultKey: string;
};

export type FailedDeposits = {
    [itemKey: string]: FailedDeposit;
};

export interface PhoneVerification {
    phoneVerificationDate?: string;
    phoneVerificationFor?: string;
}

export interface SellerAssociations {
    sellers: {[sellerId: string]: boolean};
}

export interface UserRoleAssociations {
    [uid: string]: SellerAssociations;
}

export interface UserWithId extends UserDto {
    uid: string;
}

export interface AuctionsWithWonItems {
    [regionId: string]: {
        [auctionId: string]: boolean;
    };
}

export interface AuctionsWithBids {
    [regionId: string]: {
        [auctionId: string]: {
            [itemId: string]: UserBidData | string; // string valid for auctions with bid type "auction" before migartion (changeAuctionsWithBids)
        };
    };
}

export interface UserWinningItems {
    [inventoryItemKey: string]: {
        inventoryItemKey: string;
        regionId: string;
        auctionId: string;
        itemId: string;
        bidId: string;
        amountDueOnlineCents: number;
    };
}

export interface UserUnpaidItems {
    [inventoryItemKey: string]: {
        inventoryItemKey: string;
        regionId: string;
        auctionId: string;
        itemId: string;
        amountDueOnlineCents: number;
        paymentType?: PaymentTypeEnum;
    };
}

export interface UserDto {
    email: string;
    emailLowerCase?: string;
    emailVerifiedAt?: string;
    firstName: string;
    firstNameLowerCase?: string;
    lastName: string;
    lastNameLowerCase?: string;
    companyName?: string;
    bidderNumber?: number;
    zipcode: string;
    addressUpdatedAt?: string;
    settings?: UserSettings;
    dateModified?: string;
    dateCreated?: string;
    userType?: string;
    source?: string;
    identityVerifiedByAdmin?: {
        dateVerified: string;
        verifiedBy: string;
    } | null;
    identityVerifiedByStripe?: IdentityVerifiedByStripe;
    version?: number;
    auctionsWithBids?: AuctionsWithBids;
    auctionsWithWonItems?: AuctionsWithWonItems;
    winningItems?: UserWinningItems;
    unpaidItems?: UserUnpaidItems;
    failedDeposits?: FailedDeposits;
    buyerTypeCode?: string;
    businessRoleTypeCode?: string;
    isTrusted?: boolean;
    isExemptFromSalesTax?: boolean;
    isTrustedOverride?: boolean | null;
    isSMSNotificationsEnabled?: boolean;
    isToastNotificationsEnabled?: boolean;
    hasMultiBidAccess?: boolean;
    phone?: string;
    phoneVerifiedAt?: string | null;
    phoneVerificationRequestId?: string | null;
    phoneVerificationFor?: string | null;
    phoneVerificationDate?: string | null;
    phoneVerificationBypass?: boolean | null;
    updatedBy?: string | null;
    paymentMethodsUpdatedAt?: string | null;
    favorites?: {
        auctions?: {
            [auctionId: string]: {
                regionId: string;
                sortableKey: string;
            };
        };
    };
    referral?: string;
}

// WARNING! It's a Frontend data structure! In DB and Backend we have another structure
// TODO: move to ui-models and consider renaming to FEUser
export type UserInfo = {
    uid: string; // This field is FE-only and is absent in DB
    // In the DB a valid user can't have an empty email, but in FE it can be anonymous user
    email: string | null;
    emailVerified: boolean;
    emailVerifiedAt?: string;
    phone?: string | null;
    phoneVerificationDate?: string;
    phoneVerificationRequestId?: string;
    phoneVerificationFor?: string;
    phoneVerifiedAt?: string;
    isAnonymous: boolean;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    zipcode?: string;
    settings: UserSettings | undefined;
    failedDeposits?: {
        item: any;
        paymentResultKey: string;
    }[];
    identityVerifiedByStripe?: {
        verificationIntents: any[];
        status: StripeIdentityVerificationSessionStatusEnum;
    };
    buyerTypeCode?: string;
    businessRoleTypeCode?: string;
    [index: string]: any;
    adminEmail?: string;
    impersonatedUserEmail?: string;
    impersonatedSessionId?: string;
};

import itiriri from 'itiriri';
import {flow, getSnapshot, Instance, SnapshotOut, types, applySnapshot, getRoot, SnapshotIn} from 'mobx-state-tree';
import {AuctionPauseType, AuctionTypeEnum} from '@joyrideautos/auction-core/src/types/AuctionTypes';
import {
    Auction,
    AuctionType,
    compareAuctionsByDate,
    SortingDateFieldName,
    fromAuctionDto,
    toPartialAuctionDto,
} from '../types/auction/Auction';
import {AnnouncementType, Announcement} from '../types/auction/Announcement';
import {withAliveStateRequest} from '../utils/mobxUtils';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {LoadingStatus} from '../utils/LoadingStatus';
import {RegionType} from '../types/Region';
import {AuctionSeriesType} from '../types/auction/AuctionSeries';
import {SellerType} from '../types/Seller';
import {WinningBidInfo} from '../types/WinningBid';
import {makeSubscriber, makeSubscribers} from '@joyrideautos/auction-utils/src/subscriptions';
import type {Subscribe} from '@joyrideautos/auction-utils/src/subscriptions';
import {Socket} from 'socket.io-client';
import {AuctionPath} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import BaseStore from './BaseStore';
import {
    EXTEND_BIDDING_EXPIRATION,
    TracingAttributeNameEnum,
    UPDATE_AUCTION_PAUSE,
} from '@joyrideautos/ui-services/src/services/FirebaseTraceService';
import {BidIncrementConfig, BidIncrementConfigType} from '../types/auction/BidIncrementConfig';
import {
    standartBidIncrementConfigs,
    BidIncrementTypeEnum,
    bidIncrementTypeDescriptions,
    CustomBidIncrementDto,
} from '@joyrideautos/auction-core/src/dtos/BidIncrementDto';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {PlatformFeeSchedules} from './PlatformFeeSchedules';

function makeAnnouncementKey(regionId: string, auctionId: string, live: 'regular' | 'live') {
    return `${regionId}-${auctionId}-${live}`;
}

export const AuctionsStore = BaseStore.named('AuctionsStore')
    .props({
        auctionsByRegion: types.optional(types.map(types.array(Auction)), {}),
        deletedAuctions: types.map(Auction),
        announcements: types.map(Announcement),
        incrementConfigs: types.optional(types.map(BidIncrementConfig), {
            [BidIncrementTypeEnum.JOYRIDE_STANDARD]: {
                name: bidIncrementTypeDescriptions[BidIncrementTypeEnum.JOYRIDE_STANDARD],
                steps: standartBidIncrementConfigs[BidIncrementTypeEnum.JOYRIDE_STANDARD],
            },
            [BidIncrementTypeEnum.ALWAYS_25]: {
                name: bidIncrementTypeDescriptions[BidIncrementTypeEnum.ALWAYS_25],
                steps: standartBidIncrementConfigs[BidIncrementTypeEnum.ALWAYS_25],
            },
        }),
        platformFeeSchedules: types.optional(PlatformFeeSchedules, {}),
        upcomingSequenceAuctions: types.optional(types.array(Auction), []),
        upcomingListingAuctions: types.optional(types.array(Auction), []),
    })
    .volatile(() => ({
        status: new LoadingStatus(),
        findAuctionStatus: new LoadingStatus(),
    }))
    .actions((self) => {
        const auctionsInProgress = new Set<string>();
        return {
            addDeletedAuction(auction: AuctionType) {
                self.deletedAuctions.put(auction);
            },
            fetchAndSaveDeletedAuction({regionId, auctionId}: AuctionPath) {
                if (auctionsInProgress.has(`${regionId}-${auctionId}`)) {
                    return;
                }
                auctionsInProgress.add(`${regionId}-${auctionId}`);
                self.findAuctionStatus.setInProgress();
                self.auctionService
                    .fetchAuctionFromFirestore({regionId, auctionId})
                    .then((auction) => {
                        if (auction?.deleted) {
                            this.addDeletedAuction(fromAuctionDto(auction));
                        } else {
                            self.logger.log('this auction should be found in rtdb', {regionId, auctionId});
                        }
                        auctionsInProgress.delete(`${regionId}-${auctionId}`);
                        self.findAuctionStatus.setReady();
                    })
                    .catch(logError('failed to fetch auction from firestore'));
            },
            setIncrementConfig(type: string, config: CustomBidIncrementDto) {
                self.incrementConfigs.set(type, config);
            },
            fetchIncrementConfig(type: string): void {
                const config = self.incrementConfigs.get(type);
                if (!config) {
                    self.auctionService
                        .getBidIncrementConfig(type)
                        .then((config): void => {
                            config && this.setIncrementConfig(type, config);
                        })
                        .catch(logError());
                }
            },
        };
    })
    .views((self) => ({
        get auctions(): AuctionType[] {
            return Array.from(self.auctionsByRegion.values()).flat() as AuctionType[];
        },
        get notEndedAuctionsWithItems() {
            return this.auctions.filter(
                ({ended, sellerIds, auctionId, regionId}) =>
                    !ended && sellerIds.length > 0 && this.isAuctionVisibleToCurrentUser(auctionId, regionId)
            );
        },
        getAuctionsForSellerWithCriteria(
            sellerOrSellerId: SellerType | string,
            criteria?: {
                offset?: number;
                limit?: number;
                filter?: (auction: AuctionType) => boolean;
                orderByStartDate?: {reverse: boolean; fieldName?: SortingDateFieldName};
            }
        ) {
            if (!criteria) {
                return this.auctions;
            }
            const {offset, limit, filter, orderByStartDate} = criteria;
            let auctions = filter ? this.getAuctionsForSeller(sellerOrSellerId).filter(filter) : this.auctions;
            if (offset == null || limit == null) {
                return auctions;
            }
            if (orderByStartDate) {
                auctions = auctions.sort(compareAuctionsByDate(orderByStartDate.reverse, orderByStartDate.fieldName));
            }
            return auctions.slice(offset, limit + offset);
        },
        isAuctionDeleted(regionId: string, auctionId: string): boolean | undefined {
            if (self.auctionsByRegion.get(regionId)?.find((a) => a.auctionId === auctionId)) {
                return false;
            }
            const deletedAuction = self.deletedAuctions.get(auctionId);
            if (deletedAuction) {
                return true;
            }
            self.fetchAndSaveDeletedAuction({regionId, auctionId});
            return undefined;
        },
        getAuctionsForRegion(regionId: string): AuctionType[] {
            return self.auctionsByRegion.get(regionId) || [];
        },
        get allAuctions() {
            return Array.from(self.auctionsByRegion.values()).flat();
        },
        get activeAuctions() {
            return this.allAuctions.filter(({ended}) => !ended);
        },
        getAuctionsForSeller(sellerOrSellerId: SellerType | string): AuctionType[] {
            if (typeof sellerOrSellerId === 'string') {
                const rootStore = getRoot(self) as any;
                const series: AuctionSeriesType[] =
                    rootStore.auctionSeriesStore.findSeriesAssociatedWithSeller(sellerOrSellerId);
                return series.map((series) => this.allAuctionsForSeries(series)).flat();
            } else {
                const regions = Array.from(sellerOrSellerId.regions.keys());
                return itiriri(regions)
                    .map((r) => this.getAuctionsForRegion(r))
                    .flat((auctions) => auctions)
                    .toArray();
            }
        },
        auctionsForSeries(regionId: string, seriesKey: string): AuctionType[] {
            return this.getAuctionsForRegion(regionId).filter((auction) => auction.auctionSeries === seriesKey);
        },
        allAuctionsForSeries(series: AuctionSeriesType): AuctionType[] {
            return this.auctionsForSeries(series.regionId, series.key);
        },
        upcomingAuctionsForSeries(series: AuctionSeriesType): AuctionType[] {
            return this.allAuctionsForSeries(series).filter((auction) => !auction.ended);
        },
        pastAuctionsForSeries(series: AuctionSeriesType): AuctionType[] {
            return this.allAuctionsForSeries(series).filter((auction) => Boolean(auction.ended));
        },
        findAuction(
            regionId: string,
            auctionId: string,
            options?: {
                searchInDeleted?: boolean;
            }
        ): AuctionType | undefined {
            if (!self.status.isReady) {
                return undefined;
            }
            const searchInDeleted = options?.searchInDeleted ?? false;
            const auctions = this.getAuctionsForRegion(regionId);
            const auction = auctions.find((auction) => auction.auctionId === auctionId);
            if (auction || !searchInDeleted) {
                return auction;
            }

            const deletedAuction = self.deletedAuctions.get(auctionId);
            if (!deletedAuction) {
                self.fetchAndSaveDeletedAuction({regionId, auctionId});
            }
            return deletedAuction;
        },
        findAuctionFor(auctionId: string) {
            return this.auctions.find((auction) => auction.auctionId === auctionId);
        },
        findAnnouncement(regionId: string, auctionId: string, live: 'live' | 'regular') {
            return self.announcements.get(makeAnnouncementKey(regionId, auctionId, live));
        },
        isAuctionVisibleToCurrentUser(auctionId: string, regionId: string): boolean {
            const rootStore = getRoot(self) as any;
            return rootStore.regionsStore.isRegionVisibleToCurrentUser(regionId);
        },
        getIncrementConfigAsync(type: string) {
            const config = self.incrementConfigs.get(type);
            if (!config) {
                requestAnimationFrame(() => {
                    self.fetchIncrementConfig(type);
                });
            }
            return config?.steps;
        },
        get allIncrementConfigs(): WithKey<BidIncrementConfigType>[] {
            return Array.from(self.incrementConfigs.entries()).map(([key, config]) => ({
                ...config,
                key,
            }));
        },
        getIncrementConfig(type: string): WithKey<BidIncrementConfigType> | undefined {
            const config = self.incrementConfigs.get(type);
            return config ? {...config, key: type} : undefined;
        },
    }))
    .actions((self) => ({
        setAuctions(regionId: string, auctions: SnapshotIn<AuctionType>[]) {
            self.auctionsByRegion.set(regionId, auctions);
        },
        setAnnouncement(regionId: string, auctionId: string, live: 'regular' | 'live', announcement: AnnouncementType) {
            self.announcements.set(makeAnnouncementKey(regionId, auctionId, live), announcement);
        },
        addAuction(regionId: string, auction: SnapshotOut<AuctionType>) {
            const auctions = self.auctionsByRegion.get(regionId);
            if (auctions) {
                auctions.push(auction);
            } else {
                self.auctionsByRegion.set(regionId, [auction]);
            }
        },
    }))
    .actions((self) => {
        const isValidAuction = (auction: AuctionType): boolean => {
            return !!auction.access && !!auction.auctionSeries;
        };
        const appendNewAuctions = (newAuctions: AuctionType[]) => {
            newAuctions.forEach((newAuction: AuctionType) => {
                const regionId = newAuction.regionId;
                const auctionsByRegion =
                    self.auctionsByRegion.get(regionId) || self.auctionsByRegion.set(regionId, []).get(regionId)!;
                try {
                    if (
                        isValidAuction(newAuction) &&
                        !auctionsByRegion.some((auction) => auction.auctionId === newAuction.auctionId)
                    ) {
                        auctionsByRegion.push(newAuction);
                    }
                } catch (e: any) {
                    self.logger.error(
                        `ERROR WHILE ADDING NEW AUCTION TO THE LIST: "${e.message}".\n VALUE BEING SET: "${newAuction.access}". \n AUCTION ID: "${newAuction.auctionId}" OF AUCTIONSERIES "${newAuction.auctionSeries}"`
                    );
                    self.sentryService.captureException(e);
                }
            });
        };

        const subscribeAuctionsForRegion = (regionId: string, cb?: () => void) => () => {
            return self.auctionService.subscribeToAuctions(regionId, (auctions) => {
                self.setAuctions(regionId, auctions.map<any>(fromAuctionDto));
                cb && cb();
            });
        };

        async function performAction(
            socketAction: (socket: Socket) => Promise<any>,
            functionAction: () => Promise<any>,
            args: {
                action: string;
                trace: {name: string; attributeName: string};
            }
        ) {
            const socket = self.socketWrapper.socket;

            const {action, trace} = args;
            self.firebaseTraceService.startTrace(trace.name, {
                [trace.attributeName]: socket?.connected ? 'WEB_SOCKETS' : 'CLOUD_FUNCTIONS',
            });
            try {
                if (socket && socket.connected) {
                    await socketAction(socket);
                } else {
                    await functionAction();
                }
            } catch (e: any) {
                const sentryEventId = self.sentryService.captureMessage(`error ${action}`, (scope) => {
                    scope.setextra('response', e);
                    scope.setLevel('error');
                    return scope;
                });
                console.log(`Error ${action}. Sentry event id = '${sentryEventId}'`, e.message);
            } finally {
                self.firebaseTraceService.stopTrace(EXTEND_BIDDING_EXPIRATION);
            }
        }

        return {
            extendTimer: flow(function* (auction: AuctionType) {
                yield performAction(
                    async (socket) => {
                        await self.auctionService.extendBiddingExpirationIO(
                            socket,
                            auction.regionId,
                            auction.auctionId
                        );
                    },
                    async () => {
                        await self.auctionService.extendBiddingExpiration(auction.regionId, auction.auctionId);
                    },
                    {
                        action: 'extending bidding expiration',
                        trace: {
                            name: EXTEND_BIDDING_EXPIRATION,
                            attributeName: TracingAttributeNameEnum.EXTEND_BIDDING_EXPIRATION_BE_HANDLER,
                        },
                    }
                );
            }),
            updateAuctionPause: flow(function* (auction: AuctionType, pauseValue: AuctionPauseType) {
                yield performAction(
                    async (socket) => {
                        await self.auctionService.updateAuctionPauseIO(
                            socket,
                            auction.regionId,
                            auction.auctionId,
                            pauseValue
                        );
                    },
                    async () => {
                        await self.auctionService.updateAuctionPause(auction.regionId, auction.auctionId, pauseValue);
                    },
                    {
                        action: 'updating auction pause',
                        trace: {
                            name: UPDATE_AUCTION_PAUSE,
                            attributeName: TracingAttributeNameEnum.AUCTION_PAUSE_BE_HANDLER,
                        },
                    }
                );
            }),
            endAuctionVehicle: flow(function* (winningBid: WinningBidInfo, auction: AuctionType) {
                return yield self.auctionService.endAuctionVehicle(
                    auction.regionId,
                    auction.auctionId,
                    winningBid.itemKey
                );
            }),
            skipAuctionVehicle: flow(function* (winningBid: WinningBidInfo, auction: AuctionType) {
                return yield self.auctionService.skipAuctionVehicle(
                    auction.regionId,
                    auction.auctionId,
                    winningBid.itemKey
                );
            }),
            fetchAuctionsForRegion: flow(function* (regionId: string): Generator<Promise<any>, any, any> {
                const auctions = self.getAuctionsForRegion(regionId);
                if (auctions.length === 0) {
                    const auctions = yield withAliveStateRequest(
                        self.auctionService.fetchAuctionOccurrences(regionId),
                        self
                    );
                    self.setAuctions(regionId, auctions);
                    return auctions;
                }
                return auctions;
            }),
            fetchAuctionById: flow<AuctionType | undefined, any>(function* (regionId: string, auctionId: string) {
                const auction = yield withAliveStateRequest(
                    self.auctionService.fetchAuction(regionId, auctionId),
                    self
                );
                return auction;
            }),
            fetchAuctionsForSeries: flow(function* (regionId: string, auctionSeriesKey: string) {
                const auctions = self.getAuctionsForRegion(regionId);
                if (auctions.length === 0) {
                    const auctions = yield withAliveStateRequest(
                        self.auctionService.fetchAuctionOccurrences(regionId),
                        self
                    );
                    self.setAuctions(regionId, auctions);
                }
                return self.auctionsForSeries(regionId, auctionSeriesKey);
            }),
            fetchAnnouncementByAuctionId: flow<AnnouncementType | undefined, any>(function* (
                regionId: string,
                auctionId: string,
                live: 'live' | 'regular'
            ) {
                return yield withAliveStateRequest<AnnouncementType | undefined>(
                    self.auctionService.fetchAuctionAnnouncement(regionId, auctionId, live),
                    self
                );
            }),
            subscribeToAuctionAnnouncements(regionId: string, auctionId: string, live: 'live' | 'regular') {
                return makeSubscriber(makeAnnouncementKey(regionId, auctionId, live), () => {
                    return self.auctionService.subscribeToAuctionAnnouncement(
                        regionId,
                        auctionId,
                        live,
                        (announcement) => {
                            self.setAnnouncement(regionId, auctionId, live, announcement);
                        }
                    );
                })();
            },
            fetchMultipleAuctions: flow(function* (
                auctions: {regionId: string; auctionId: string}[]
            ): Generator<Promise<any>, any, any> {
                const auctionsToFetch = auctions.filter((auctionToFetch) => {
                    const auctionsByRegion = self.auctionsByRegion.get(auctionToFetch.regionId) || [];
                    return !auctionsByRegion.some((auction) => auction.auctionId === auctionToFetch.auctionId);
                });

                const newAuctions: AuctionType[] = yield self.auctionService.fetchMultipleAuctionOccurences(
                    auctionsToFetch
                );

                appendNewAuctions(newAuctions);

                return itiriri(auctions)
                    .groupBy(
                        (a) => a.regionId,
                        (a) => a.auctionId
                    )
                    .flat(([regionId, auctions]) => {
                        const auctionsByRegion = self.auctionsByRegion.get(regionId) || [];
                        return auctionsByRegion.filter((a) => auctions.includes(a.auctionId));
                    })
                    .toArray();
            }),
            subscribe(regions: RegionType[]) {
                return makeSubscribers(
                    `AuctionsStore-${regions.map(({regionId}) => regionId).join('-')}`,
                    regions.reduce<Record<string, Subscribe>>((subscribers, {regionId}, i) => {
                        self.status.setInProgress();
                        subscribers[`AuctionsStore-${regionId}`] = subscribeAuctionsForRegion(regionId, () => {
                            // TODO: find better approach to track loading status
                            if (i === regions.length - 1) {
                                self.status.setReady();
                            }
                        });
                        return subscribers;
                    }, {})
                )();
            },
            subscribeToBidIncrements() {
                return makeSubscriber(`bid-increments`, () =>
                    self.auctionService.subscribeToBidIncrements((bidIncrements) => {
                        for (const [type, config] of Object.entries(bidIncrements)) {
                            self.setIncrementConfig(type, config);
                        }
                    })
                )();
            },
            subscribeToUpcomingSequenceAuctions(limit?: number) {
                return makeSubscriber(`upcoming-sequence-auctions`, () =>
                    self.auctionService.subscribeToUpcomingAuctionsByType(
                        AuctionTypeEnum.SEQUENCE,
                        limit,
                        (auctions) => {
                            applySnapshot(
                                self.upcomingSequenceAuctions,
                                auctions.map(({key, ...auction}) => ({...auction, auctionId: key}))
                            );
                        }
                    )
                )();
            },
            subscribeToUpcomingListingAuctions(limit?: number) {
                return makeSubscriber(`upcoming-listing-auctions`, () =>
                    self.auctionService.subscribeToUpcomingAuctionsByType(
                        AuctionTypeEnum.LISTING,
                        limit,
                        (auctions) => {
                            applySnapshot(
                                self.upcomingListingAuctions,
                                auctions.map(({key, ...auction}) => ({...auction, auctionId: key}))
                            );
                        }
                    )
                )();
            },
        };
    })
    .actions((self) => ({
        createOrUpdateAuction: flow(function* (regionId: string, auctionDto: Partial<AuctionType>) {
            const {auctionId, ...restDto} = auctionDto;

            if (auctionId) {
                // update existing auction
                const auctionModel = self.findAuction(regionId, auctionId);
                const auction = auctionModel && getSnapshot(auctionModel);
                if (!auction) {
                    throw new Error('Unexpected error');
                }
                const updatedAuction = yield self.auctionService.updateAuctionOccurrence(
                    regionId,
                    auctionId,
                    toPartialAuctionDto({...auction, ...restDto} as AuctionType),
                    false
                );

                if (auction) {
                    applySnapshot(auctionModel, updatedAuction);
                } else {
                    self.addAuction(regionId, updatedAuction);
                }
            } else {
                // create a new auction (new auction occurrence record will be added to the store by subscription)
                yield self.auctionService.updateAuctionOccurrence(
                    regionId,
                    auctionId,
                    toPartialAuctionDto(restDto),
                    false
                );
            }
        }),
        rescheduleAuction: flow(function* (
            regionId: string,
            auctionDto: Partial<AuctionType>,
            initialStart: string | undefined,
            newEventStart: string | undefined
        ) {
            const {auctionId} = auctionDto;
            if (auctionId) {
                return yield self.auctionService.rescheduleAuctionOccurrence(
                    regionId,
                    auctionId,
                    initialStart,
                    newEventStart
                );
            }
            return false;
        }),
        updateAuctionAnnouncement: flow(function* (
            regionId: string,
            auctionId: string,
            live: 'live' | 'regular',
            announcementDto: AnnouncementType
        ) {
            yield self.auctionService.updateAuctionAnnouncement(regionId, auctionId, live, announcementDto);
        }),
        fetchAuctionsForSeller: flow(function* (seller: SellerType) {
            const promises: Promise<AuctionType[]>[] = Array.from(seller.regions.keys()).map((region) => {
                return self.fetchAuctionsForRegion(region);
            });
            const results = yield Promise.all(promises);
            return itiriri<AuctionType[][]>(results)
                .flat((r) => r)
                .flat((r) => r)
                .toArray();
        }),
    }));

export interface AuctionsStoreType extends Instance<typeof AuctionsStore> {}

export interface HasAuctionsStore {
    auctionsStore: AuctionsStoreType;
}

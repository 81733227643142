import axios from 'axios';
import {initializeApp} from 'firebase/app';
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';
import {createDatabase} from '@joyrideautos/web-firebase-client/src/firebaseServices/Database';
import {createAuth} from '@joyrideautos/web-firebase-client/src/firebaseServices/Auth';
import makeLogger, {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';
import {createFirestore} from '@joyrideautos/web-firebase-client/src/firebaseServices/Firestore';
import {createStorage} from '@joyrideautos/web-firebase-client/src/firebaseServices/Storage';
import {createPerformance} from '@joyrideautos/web-firebase-client/src/firebaseServices/Performance';
import {createRPCService} from '@joyrideautos/web-firebase-client/src/services/rpcService/createRPCService';
import {createFERoutingService} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {createAnalytics} from '@joyrideautos/web-firebase-client/src/firebaseServices/Analytics';
import {UIServicesFactory} from '@joyrideautos/ui-services/src/UIServicesFactory';
import {createSentryService} from '@joyrideautos/ui-services/src/services/SentryService';
import {MediaService} from '@joyrideautos/aws-client/src/services/MediaService';
import {getCredentialsProvider} from '@joyrideautos/aws-client/src/AWSCredentialsProvider';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

class WebServicesFactory extends UIServicesFactory {
    createMediaService() {
        return new MediaService(
            this.firebase,
            this.config,
            makeLogger(this.sentryService)('MediaService', LoggerStatusEnum.ENABLED)
        );
    }

    getCredentialsProvider() {
        return getCredentialsProvider(
            this.firebase.auth,
            makeLogger(this.sentryService)('AWSCredentialsProvider', LoggerStatusEnum.DISABLED)
        );
    }
}

export function getFirebaseServicesFactory(appConfig: AppConfig, history: any, _fetch = fetch) {
    console.debug(
        `Routing config:\n` +
            `  defaultRequestHandler = ${appConfig.microservicesConfig.defaultRequestHandler}\n` +
            `  appHttpUrl = ${appConfig.microservicesConfig.appHttpUrl}\n` +
            `  sellersHttpUrl = ${appConfig.microservicesConfig.sellersHttpUrl}\n` +
            `  searchHttpUrl = ${appConfig.microservicesConfig.searchHttpUrl}\n` +
            `  auctionsHttpUrl = ${appConfig.microservicesConfig.auctionsHttpUrl}\n` +
            `  itemsHttpUrl = ${appConfig.microservicesConfig.itemsHttpUrl}\n` +
            `  paymentsHttpUrl = ${appConfig.microservicesConfig.paymentsHttpUrl}\n` +
            `  usersHttpUrl = ${appConfig.microservicesConfig.usersHttpUrl}\n` +
            `  phonesHttpUrl = ${appConfig.microservicesConfig.phonesHttpUrl}\n` +
            `  bidsHttpUrl = ${appConfig.microservicesConfig.bidsHttpUrl}\n` +
            `  biddingWsUrl = ${appConfig.microservicesConfig.biddingWsUrl}\n` +
            `  functionsURL = ${appConfig.emulatorsConfig.functionsUrl}`
    );

    const firebaseApp = initializeApp(appConfig.firebaseConfig);

    const functions = getFunctions(firebaseApp);

    if (appConfig.emulatorsConfig.functionsEmulatorHost && appConfig.isEmulatedEnv) {
        console.log('testing locally -- hitting local functions and realtime database emulators', firebaseApp.options);
        const [host, port] = (appConfig.emulatorsConfig.functionsEmulatorHost || '').split(':');
        connectFunctionsEmulator(functions, `${host}`, Number.parseInt(port || '5001'));
    }

    const sentryService = createSentryService(
        Sentry,
        makeLogger(undefined, {loggerStatusEnabled: appConfig.commonConfig.loggerStatusEnabled})(
            'SentryService',
            LoggerStatusEnum.DISABLED
        ),
        appConfig.commonConfig.sentryDsn
            ? {
                  dsn: appConfig.commonConfig.sentryDsn,
                  integrations: [
                      history &&
                          new Integrations.BrowserTracing({
                              routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                          }),
                  ],
                  environment: appConfig.commonConfig.sentryEnv,
                  release: process.env.REACT_APP_BITBUCKET_TAG || process.env.REACT_APP_BITBUCKET_BUILD_NUMBER,
                  tracesSampleRate: 0,
                  ignoreErrors: [
                      // This error if harmless, but causes exceeding the Sentry limits
                      // https://stackoverflow.com/questions/65732703/resizeobserver-loop-limit-exceeded-stop-exception
                      'ResizeObserver loop limit exceeded',
                      // https://bugs.webkit.org/show_bug.cgi?id=273827
                      // REGRESSION (iOS 17.4): IndexedDB failing with "UnknownError: Connection to Indexed Database server lost.
                      'Connection to Indexed Database server lost.',
                      // Mostly happens on iOS. This error if harmless, but causes exceeding the Sentry limits
                      'AbortError: The operation was aborted.',
                  ],
              }
            : undefined
    );

    const database = createDatabase(
        firebaseApp,
        appConfig,
        makeLogger()('RootStore.Database', LoggerStatusEnum.DISABLED)
    );
    const auth = createAuth(firebaseApp, appConfig, makeLogger()('RootStore.Auth', LoggerStatusEnum.DISABLED));
    return new WebServicesFactory(
        {
            auth,
            database,
            firestore: createFirestore(
                firebaseApp,
                appConfig,
                makeLogger()('RootStore.Firestore', LoggerStatusEnum.DISABLED)
            ),
            storage: createStorage(
                firebaseApp,
                appConfig,
                makeLogger()('RootStore.Storage', LoggerStatusEnum.DISABLED)
            ),
            performance: createPerformance(
                firebaseApp,
                appConfig,
                makeLogger()('RootStore.Performance', LoggerStatusEnum.DISABLED)
            ),
            rpcService: {
                call: (route: string, options?: any) => (params: any) => {
                    return createRPCService(auth, _fetch).call(route, params, options);
                },
                post(url: string, data?: any, config?: any) {
                    return axios.post(url, data, config);
                },
                getUrlForRoute(route: string) {
                    return createFERoutingService({
                        projectId: appConfig.firebaseConfig.projectId,
                        functionUrl: appConfig.emulatorsConfig.functionsUrl,
                        microservices: appConfig.microservicesConfig,
                    }).getUrlForRoute(route);
                },
            },
            analytics: createAnalytics(
                firebaseApp,
                auth,
                appConfig,
                makeLogger()('RootStore.Analytics', LoggerStatusEnum.DISABLED)
            ),
        },
        appConfig,
        sentryService
    );
}

import {RecaptchaVerifier} from '../firebase/Auth';
import {BaseService} from './BaseService';

const recaptchaRegistry: Map<string, RecaptchaVerifier> = new Map<string, RecaptchaVerifier>();

const getRecaptchaKey = (domElementId: string) => domElementId;

export class RecaptchaService extends BaseService {
    addInvisibleRecaptcha(domElementId: string) {
        if (this.isRecaptchaDisabled) {
            return;
        }
        const registryKey = getRecaptchaKey(domElementId);

        if (!recaptchaRegistry.has(registryKey)) {
            recaptchaRegistry.set(registryKey, this.firebase.auth.createRecaptchaVerifier(domElementId));
        }
    }

    removeRecaptcha(domElementId: string) {
        if (this.isRecaptchaDisabled) {
            return;
        }
        const registryKey = getRecaptchaKey(domElementId);

        if (recaptchaRegistry.has(registryKey)) {
            const recaptcha = recaptchaRegistry.get(registryKey)!;
            recaptcha.clear();
            recaptchaRegistry.delete(registryKey);
        }
    }

    verifyRecaptcha(domElementId: string): Promise<any> {
        if (this.isRecaptchaDisabled) {
            return Promise.resolve();
        }
        const registryKey = getRecaptchaKey(domElementId);

        if (recaptchaRegistry.has(registryKey)) {
            const recaptcha = recaptchaRegistry.get(registryKey)!;
            return recaptcha.verify();
        } else {
            throw new Error(`No recaptcha instance registered for the key: ${domElementId}`);
        }
    }

    private get isRecaptchaDisabled() {
        return this.config.commonConfig.recaptchaDisabled;
    }
}

import {getEnv, Instance} from 'mobx-state-tree';
import {Routes} from '../../../Routes';
import {toJS} from 'mobx';
import {PayInFullBannerViewModel} from './PayInFullBannerViewModel';

const allowedRoutes = [
    Routes.AUCTION_OCCURRENCE({
        pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
    }),
    Routes.VEHICLE_PROFILE({
        pathParams: {regionId: ':regionId', auctionId: ':auctionId', itemId: ':itemId'},
    }),
];

export const AddPaymentMethodBannerViewModel = PayInFullBannerViewModel.named('AddPaymentMethodBannerViewModel')
    .props({})
    .views((self) => ({
        get env() {
            return getEnv(self);
        },
        get regionId(): string | undefined {
            return self.rootStore.presentationStore.routeModel.getRouteParamByName('regionId');
        },
        get auctionId(): string | undefined {
            return self.rootStore.presentationStore.routeModel.getRouteParamByName('auctionId');
        },
        get itemId(): string | undefined {
            return self.rootStore.presentationStore.routeModel.getRouteParamByName('itemId');
        },
        get isPayInFullEnabled(): boolean {
            if (!this.isOnSpecificPages) {
                return false;
            }

            if (this.isOnVehicleDetailsPage) {
                return self.checkPayInFullForItem({
                    regionId: this.regionId!,
                    auctionId: this.auctionId!,
                    itemId: this.itemId!,
                });
            }

            if (this.isOnAuctionPage) {
                return self.checkPayInFullForAuction({regionId: this.regionId!, auctionId: this.auctionId!});
            }

            return false;
        },
        get isOnVehicleDetailsPage() {
            return this.regionId && this.auctionId && this.itemId;
        },

        get isOnAuctionPage() {
            return this.regionId && this.auctionId && !this.itemId;
        },

        get hasPayment() {
            return self.authUser && toJS(self.authUser.paymentMethods).length > 0;
        },
        get hasManagerRoleForAuction(): boolean {
            if (!this.auctionId || !this.regionId) {
                return false;
            }
            return self.managerRoleStore.hasManagerRoleForAuction(this.regionId, this.auctionId);
        },
        get isOnSpecificPages() {
            return allowedRoutes.includes(this.env.path);
        },
        get isActive() {
            if (!self.authUser || self.authUser.loadPaymentMethodsStatus.isInProgress) {
                return false;
            }
            return this.isPayInFullEnabled && !this.hasPayment && !this.hasManagerRoleForAuction;
        },
    }));

export interface AddPaymentMethodBannerViewModelType extends Instance<typeof AddPaymentMethodBannerViewModel> {}

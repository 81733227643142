import {filterUndef} from '@joyrideautos/auction-utils/src/objectUtils';
import {DocumentSnapshot} from './firebase/Firestore';
import {WithKey} from './firebase/types';

export function parseJwt(token: string) {
    const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
            .join('')
    );
    return JSON.parse(jsonPayload);
}

export function toArrayWithKey<T>(snapshots: DocumentSnapshot<T>[] | null): WithKey<T>[] {
    return snapshots
        ? filterUndef(
              snapshots.map((snapshot) => {
                  const data = snapshot.data();
                  return data && {...data, key: snapshot.id};
              })
          )
        : [];
}

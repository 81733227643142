import {observable} from 'mobx';
import {AuctionPath} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import {LoadingStatus} from '../utils/LoadingStatus';
import {
    createItemsStore,
    createPersistedItemsStore,
    createPersistedItemsStoreForSeller,
    ItemsContainerLike,
    ItemsStoreType,
    PersistedItemsStoreForSellerType,
    PersistedItemsStoreType,
} from './ItemsStore';
import {AuthUserType} from '../types/UserInfo';
import {hoursFromStartTime} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {BidTypeEnum} from '@joyrideautos/auction-core/src/dtos/BidDto';
import {ACTIVE_OFFER_DURATION_HOURS} from '@joyrideautos/auction-core/src/constants/Constants';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';
import {PersistedItemStatusEnum} from '@joyrideautos/auction-core/src/types/ItemTypes';
import type {ItemType} from '../types/item/Item';
import {mapAuctionItemDtoToModel, mapInventoryItemDtoToAuctionItemModel} from '../types/item/Item';
import {mapInventoryItemDtoToModel, PersistedItemType} from '../types/item/PersistedItem';
import {CachedPersistedItemsStoreType, createCachedItemsStore} from './CachedItemsStore';
import {UIServicesAwareViewModelType} from '../common/UIServicesAwareViewModel';
import {filterUndef} from '@joyrideautos/auction-utils/src/objectUtils';
import {destroy} from 'mobx-state-tree';
import {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';

const GLOBAL_PERSISTENT_ITEMS_STORE_KEY = 'globalPersistentItemsStore';
const AUCTIONS_PERSISTENT_ITEMS_STORE_KEY = 'auctionsPersistentItemsStore';
const PERSISTENT_ITEMS_STORE_FOR_WON_ITEMS_KEY = 'PERSISTENT_ITEMS_STORE_FOR_WON_ITEMS_KEY';

function makeComposeKey(...parts: string[]) {
    return parts.join('-');
}

function makeAuctionItemsKey({regionId, auctionId}: AuctionPath) {
    return `${regionId}-${auctionId}`;
}

function makeActiveBidItemsKey(uid: string) {
    return `activeBidItems-${uid}`;
}

function makeOfferItemsKey(uid: string) {
    return `offer-${uid}`;
}

function makeFavoriteItemsKey(uid: string) {
    return `favoriteItems-${uid}`;
}

export class ItemsStoreFactory {
    // @ts-ignore
    private logger: Logger;

    constructor(private rootStore: UIServicesAwareViewModelType) {
        this.logger = rootStore.getLogger('ItemsStoreFactory', LoggerStatusEnum.ENABLED);
    }

    private stores = observable.map<string, ItemsStoreType>();

    getItemsStoreForAuction(auctionPath: AuctionPath): ItemsStoreType {
        const storeKey = makeAuctionItemsKey(auctionPath);
        if (!this.stores.has(storeKey)) {
            const store = createItemsStore({}, {rootStore: this.rootStore});
            store.setItemsSubscription((setItems) =>
                store.itemsService.subscribeToItems(auctionPath, (items) =>
                    setItems(items.map(mapAuctionItemDtoToModel))
                )
            );

            this.stores.set(storeKey, store);
        }
        return this.stores.get(storeKey)!;
    }

    destroyItemsStoreForAuction(auctionPath: AuctionPath): void {
        const storeKey = makeAuctionItemsKey(auctionPath);
        if (this.stores.has(storeKey)) {
            const store = this.stores.get(storeKey);
            this.stores.delete(storeKey);
            destroy(store);
        }
    }

    getItemsStoreForPastAuction(auctionPath: AuctionPath): ItemsStoreType {
        const storeKey = makeAuctionItemsKey(auctionPath);
        if (!this.stores.has(storeKey)) {
            const store = createItemsStore({}, {rootStore: this.rootStore});
            store.setItemsSubscription((setItems) => {
                let canceled = false;
                // here we have IIFE which returns a promise (the function type, in this case, is: () => Promise<void>). and the 'no-floating-promises' rule catches this issue.
                (async () => {
                    const items = await store.itemsService.getInventoryItemsForAuction(auctionPath);
                    if (canceled) {
                        return;
                    }
                    setItems(filterUndef(items.map(mapInventoryItemDtoToAuctionItemModel)));
                })().catch((e) => console.log(e));
                return () => {
                    canceled = true;
                };
            });

            this.stores.set(storeKey, store);
        }
        return this.stores.get(storeKey)!;
    }

    getActiveBidItemsStore(authUser: AuthUserType): ItemsStoreType {
        if (!authUser) {
            throw Error(`Illegal argument: ${authUser}`);
        }

        const storeKey = makeActiveBidItemsKey(authUser.uid);

        if (!this.stores.has(storeKey)) {
            const store = createItemsStore({}, {rootStore: this.rootStore});
            store.setItemsSubscription((setItems) => {
                return store.itemsService.subscribeToUserBiddenItems(authUser.uid, async (itemsRefs) => {
                    store.loadingStatus.setInProgress();
                    const items = await store.itemsService.fetchItems(itemsRefs);
                    setItems(items.map(mapAuctionItemDtoToModel));
                });
            });

            this.stores.set(storeKey, store);
        }

        return this.stores.get(storeKey)!;
    }

    getBuyerOffersItemsStore(authUser: AuthUserType): ItemsStoreType {
        if (!authUser) {
            throw Error(`Illegal argument: ${authUser}`);
        }

        const filterOffers = (item: string | {type: BidTypeEnum; timestamp: string}) => {
            if (typeof item === 'string') {
                return false;
            }
            const {type, timestamp} = item;
            return (
                type === BidTypeEnum.OFFER &&
                hoursFromStartTime(new Date(timestamp).getTime()) < ACTIVE_OFFER_DURATION_HOURS
            );
        };

        const storeKey = makeOfferItemsKey(authUser.uid);

        if (!this.stores.has(storeKey)) {
            const store = createItemsStore({}, {rootStore: this.rootStore});
            store.setItemsSubscription((setItems) => {
                return store.itemsService.subscribeToUserBiddenItems(
                    authUser.uid,
                    async (itemsRefs) => {
                        store.loadingStatus.setInProgress();
                        const items = await store.itemsService.fetchItems(itemsRefs);
                        setItems(items.map(mapAuctionItemDtoToModel));
                    },
                    filterOffers
                );
            });
            this.stores.set(storeKey, store);
        }
        return this.stores.get(storeKey)!;
    }

    getSellerOffersItemsStore(sellerId: string): ItemsStoreType {
        const store = createItemsStore({}, {rootStore: this.rootStore});
        store.setItemsSubscription((setItems) => {
            let canceled = false;
            (async () => {
                const items = await store.bidService.fetchAllItemsWithOffers(sellerId);
                if (canceled) {
                    return;
                }
                setItems(items);
            })().catch((e) => console.log(e));
            return () => {
                canceled = true;
            };
        });

        return store;
    }

    getFavoriteItemsStore(uid: string) {
        if (!uid) {
            throw Error(`Illegal argument: ${uid}`);
        }

        const storeKey = makeFavoriteItemsKey(uid);
        if (!this.stores.has(storeKey)) {
            const store = createItemsStore({}, {rootStore: this.rootStore});
            this.stores.set(storeKey, store);
        }

        return this.stores.get(storeKey)!;
    }

    destroyFavoriteItemsStore(uid: string): void {
        const storeKey = makeFavoriteItemsKey(uid);
        if (this.stores.has(storeKey)) {
            const store = this.stores.get(storeKey);
            this.stores.delete(storeKey);
            destroy(store);
        }
    }

    get cachedPersistentItemsStore() {
        if (!this.stores.has('cachedPersistentItemsStore')) {
            const store = createCachedItemsStore({}, {max: 50, rootStore: this.rootStore});
            this.stores.set('cachedPersistentItemsStore', store as any);
        }
        return cast<CachedPersistedItemsStoreType>(this.stores.get('cachedPersistentItemsStore')!);
    }

    get persistentItemsStore() {
        if (!this.stores.has(GLOBAL_PERSISTENT_ITEMS_STORE_KEY)) {
            const store = createCachedItemsStore({}, {max: 300, rootStore: this.rootStore});
            this.stores.set(GLOBAL_PERSISTENT_ITEMS_STORE_KEY, store as any);
        }
        return cast<CachedPersistedItemsStoreType>(this.stores.get(GLOBAL_PERSISTENT_ITEMS_STORE_KEY)!);
    }

    getPersistentItemsStoreForSeller(sellerId: string, status?: PersistedItemStatusEnum) {
        const storeKey = `persistentItemsStore-${sellerId}-${status ? status : 'status'}`;
        if (!this.stores.has(storeKey)) {
            const store = createPersistedItemsStoreForSeller({sellerId, status}, {rootStore: this.rootStore});
            this.stores.set(storeKey, store as any);
        }
        return cast<PersistedItemsStoreForSellerType>(this.stores.get(storeKey)!);
    }

    get persistentItemsStoreForAuctions() {
        if (!this.stores.has(AUCTIONS_PERSISTENT_ITEMS_STORE_KEY)) {
            const store = createPersistedItemsStore({}, {rootStore: this.rootStore});
            this.stores.set(AUCTIONS_PERSISTENT_ITEMS_STORE_KEY, store as any);
        }
        return cast<PersistedItemsStoreType>(this.stores.get(AUCTIONS_PERSISTENT_ITEMS_STORE_KEY)!);
    }

    get persistedItemsStoreForWonItems() {
        const authUser: AuthUserType = (this.rootStore as any).authUserStore.userInfo;
        if (!authUser) {
            throw Error(`Illegal argument: ${authUser}`);
        }
        const key = makeComposeKey(PERSISTENT_ITEMS_STORE_FOR_WON_ITEMS_KEY, authUser.uid);
        if (!this.stores.has(key)) {
            const store = createPersistedItemsStore({}, {rootStore: this.rootStore});
            store.setItemsSubscription((setItems) =>
                this.rootStore.wonItemService.subscribeToPersistedWonItems({uid: authUser.uid}, (items) => {
                    setItems(items.map(mapInventoryItemDtoToModel));
                })
            );
            this.stores.set(key, store as any);
        }
        return cast<PersistedItemsStoreType>(this.stores.get(key)!);
    }

    getPersistedItemsStoreForAuctionWonItems(auctionId: string) {
        const authUser: AuthUserType = (this.rootStore as any).authUserStore.userInfo;
        if (!authUser) {
            throw Error(`Illegal argument: ${authUser}`);
        }
        const key = makeComposeKey(PERSISTENT_ITEMS_STORE_FOR_WON_ITEMS_KEY, authUser.uid, auctionId);
        if (!this.stores.has(key)) {
            const store = createPersistedItemsStore({}, {rootStore: this.rootStore});
            store.setItemsSubscription((setItems) =>
                this.rootStore.wonItemService.subscribeToPersistedWonItems({uid: authUser.uid, auctionId}, (items) => {
                    setItems(items.map(mapInventoryItemDtoToModel));
                })
            );
            this.stores.set(key, store as any);
        }
        return cast<PersistedItemsStoreType>(this.stores.get(key)!);
    }
}

export const makeItemsContainerForItems = (
    items: ItemType[],
    loadingStatus: LoadingStatus = new LoadingStatus(),
    loader?: () => Promise<any>
): ItemsContainerLike<ItemType> => {
    return {
        getAllItems() {
            return items;
        },
        get allItems() {
            return items;
        },
        getItem(regionId, auctionId, itemKey) {
            return items.find((i) => i.regionId === regionId && i.auctionId === auctionId && i.key === itemKey);
        },
        getItemByKey(itemKey) {
            return items.find((i) => i.persistenceKey === itemKey);
        },
        get loadingStatus() {
            return loadingStatus;
        },
        reload: loader,
    };
};

export const makeItemsContainerForPersistedItems = (
    items: PersistedItemType[],
    loadingStatus: LoadingStatus = new LoadingStatus(),
    loader: () => Promise<any> = () => {
        return Promise.resolve();
    }
): ItemsContainerLike<PersistedItemType> => {
    return {
        getAllItems() {
            return items;
        },
        get allItems() {
            return items;
        },
        getItem(regionId, auctionId, itemKey) {
            return this.getItemByKey(itemKey);
        },
        getItemByKey(itemKey: string) {
            return items.find((i) => i.key === itemKey);
        },
        get loadingStatus() {
            return loadingStatus;
        },
        reload: loader,
    };
};
